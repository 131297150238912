import React, { Fragment } from "react"
import { useStaticQuery, graphql } from "gatsby"

import Layout from "../../components/common/Layout/layout"
import SEO from "../../components/common/SEO/seo"
import BannerPage from "../../components/banner/BannerPage/bannerPage"
import SectionTextImage from "../../components/section/TextImage/textImage"
import CallToAction from "../../components/section/CallToAction/callToAction"

const BrandingPage = () => {
    const data = useStaticQuery(graphql`
    query {
      brandingImage1: file(relativePath: { eq: "images/sections/diseno-grafico/branding/porque-es-importante-branding-ibxagency.png" }) {
        childImageSharp {
          fluid(maxWidth: 854) {
            ...GatsbyImageSharpFluid
          }
        }
      },
      brandingImage2: file(relativePath: { eq: "images/sections/diseno-grafico/branding/necesitas-un-logo-ibxagency.png" }) {
        childImageSharp {
          fluid(maxWidth: 854) {
            ...GatsbyImageSharpFluid
          }
        }
      },
      brandingImage3: file(relativePath: { eq: "images/sections/diseno-grafico/branding/branding-360-ibxagency.png" }) {
        childImageSharp {
          fluid(maxWidth: 854) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

    return (
        <Layout>
            <SEO
                title="Branding"
                description="Agencia de Publicidad en Barcelona experta en branding. Nuestra especialidad es el diseño de la  identidad corporativa para empresas."
            />

            <BannerPage
                title="Expertos en branding"
                text="Diseñamos la identidad corporativa de tu empresa"
                color="pink"
            />

            <SectionTextImage
                title1="El branding"
                title2="¿Por qué importa?"
                text={<Fragment>
                    Porqué es aquello que define cómo es tu marca  y tu negocio en el mercado. La <strong>identidad corporativa de una empresa</strong> detalla a la perfección quién eres y en qué sector operas. Es aquel sello que hace distinguirse de la competencia. <br /><br />
                    Si tú te preocupas por tu imagen, ¿por qué no te preocupa la de tu empresa? <strong>Una imagen bien definida hará que te recuerden y dará visibilidad a tu marca</strong>.
                    </Fragment>}
                image={data.brandingImage1.childImageSharp.fluid}
                imageAlt="¿Por qué hacer branding? - Agencia Branding"
                color="pink"
                containerTextRight={false}
            />

            <SectionTextImage
                title1="¿Necesitas un "
                title2="logo?"
                text={<Fragment>
                    En IBX somos <strong>especialistas en la creación de logos para empresas</strong>. Creamos aquella imagen que necesita tu negocio. Llevamos a cabo tanto el <strong>diseño de logotipos desde cero</strong> como de su <strong>restylings</strong>, si lo que buscas es modernizar o darle un giro a tu marca. <br /><br />
                    <strong>Diseñamos logotipos</strong> únicos siguiendo las últimas tendencias en el <strong>diseño de logos</strong> del mercado. Basamos nuestros trabajos en la simpleza y la sencillez, siempre bajo el lema "menos es más".
                </Fragment>}
                image={data.brandingImage2.childImageSharp.fluid}
                imageAlt="¿Necesitas un logo? - Agencia Branding"
                color="pink"
                containerTextRight={true}
            />

            <SectionTextImage
                title1="Branding "
                title2="360"
                text={<Fragment>
                    Nuestra <strong>agencia de publicidad</strong> ofrece un servicio global de branding:<br />
                    <ul class="text-left">
                        <li>Creación y diseño de marcas (namings, slogans, claims)</li>
                        <li>Rediseño de marcas (rebranding)</li>
                        <li>Logotipos e isotipos</li>
                        <li>Manuales de marcas</li>
                        <li>Guías de estilo</li>
                        <li>Identidades corporativas</li>
                    </ul>
                    Nuestro objetivo es <strong>crear una imagen corporativa</strong> que se ajuste a los valores y principios de una empresa o marca.
                </Fragment>}
                image={data.brandingImage3.childImageSharp.fluid}
                imageAlt="Branding 360 - Agencia Branding"
                color="pink"
                containerTextRight={false}
            />

            <CallToAction
                color="pink"
                title="¿Necesitas crear la identidad corporativa de tu marca?"
                text={<Fragment>
                    Somos tu <strong>agencia de publicidad en Barcelona experta en branding</strong>
                </Fragment>}
                button="¿Empezamos?"
                buttonUrl="/contacto"
            />

        </Layout>
    )

}
export default BrandingPage
